/* eslint-disable react/no-unescaped-entities */
import React from 'react';
// import { LazyLoadImage } from 'react-lazy-load-image-component';
// import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

const mock = [
  // independence-day-2023
  {   
    description:
      'Independence Day (Class 6-8)',
    href : '/independence-day-2023'
 
  },
  {   
    description:
      'Independence Day (Pre-Primary)',
    href : '/school-program-2023'
 
  },
  {   
    description:
      'Onam Celebration',
    href : '/onam-2023'
 
  },
  {   
    description:
      'Teacher’s Day Celebration',
    href : '/teacher-day-2023'
 
  },
  {   
    description:
      'Hindi Diwas',
    href : '/hindi-diwas-2023'
 
  },
  {   
    description:
      'Gandhi Jayanthi',
    href : '/gandi-jayanti-2023'
 
  },
  {   
    description:
      'Pre Primary Dussehra',
    href : '/dussehra-celebration-2023'
 
  },
  {   
    description:
      'Rajyotsava Day',
    href : '/rajyotsavaday2023'
 
  },
  {   
    description:
      'Children’s Day ',
    href : '/childrensday2023'
 
  },
  {   
    description:
      'Pre Primary Children’s Day ',
    href : '/prechildrensday2023'
 
  },
  {   
    description:
      'Scientifica',
    href : '/scientifica-2023'
 
  },
  {   
    description:
      'Christmas',
    href : '/christmas-2023'
  },
  {   
    description:
      'Cultural Fiesta',
    href : '/cultural-fiesta-2023'
  },
  {   
    description:
      'Republic Day',
    href : '/republic-day-2023'
 
  },
  {   
    description:
      'Graduation Day',
    href : '/graduationday-2023'
 
  },
  {   
    description:
      'Science Exhibition',
    href : '/science-exhibition-2023'
 
  },
  {   
    description:
      'Valediction Ceremony 9-12',
    href : '/valediction-ceremony-2023'
 
  },
  {   
    description:
      'Valediction Ceremony 6-8',
    href : '/valediction-ceremony6to8-2023'
 
  },
];

const Sidebar2023 = () => {
  
  return (
    <Box component={Card} variant={'outlined'} padding={2}>
      
      <Typography
        variant="h6"
        data-aos={'fade-up'}
        sx={{
          fontWeight: 700,
          marginBottom: 2,
        }}
      >
        School Programs 2023-24
      </Typography>
      <Grid container spacing={2}>
        {mock.map((item, i) => (
          <Grid key={i} item xs={12}>
            <Box
              component={Card}
              width={1}
              height={1}
              boxShadow={0}
              borderRadius={0}
              display={'flex'}
              flexDirection={{ xs: 'column', md: 'row' }}
              sx={{ backgroundImage: 'none', bgcolor: 'transparent' }}
            >
              <Button href = {item.href}>
                <Typography component={'P'}>
                  {item.description}
                </Typography>
              </Button>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Sidebar2023;
