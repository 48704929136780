import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import Sidebar2023 from '../Sidebar2023';

const Scientifica2023 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const [config, setConfig] = useState({ base_image_archive_url: '' });
useEffect(() => {
  fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
    .then(response => response.json())
    .then(data => setConfig(data))
    .catch(error => console.error('Error fetching config:', error));
}, []);

const p1 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/scientfica/1.webp`;
const p2 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/scientfica/2.webp`;
const p3 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/scientfica/3.webp`;
const p4 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/scientfica/4.webp`;
const p5 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/scientfica/5.webp`;
const p6 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/scientfica/6.webp`;
const p7 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/scientfica/7.webp`;
const p8 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/scientfica/8.webp`;
const p9 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/scientfica/9.webp`;
const p10 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/scientfica/10.webp`;
const p11 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/scientfica/11.webp`;
const p12 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/scientfica/12.webp`;
const p13 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/scientfica/13.webp`;


    const photos = [
        {
            src: p2,
            source: p2,
            rows: 1.2,
            cols: 2,
        },
        {
            src: p1,
            source: p1,
            rows: 1,
            cols: 1,
        },
        {
            src: p3,
            source: p3,
            rows: 1,
            cols: 1,
        },
        {
            src: p4,
            source: p4,
            rows: 1,
            cols: 1,
        },
        {
            src: p5,
            source: p5,
            rows: 1,
            cols: 1,
        },
        {
            src: p6,
            source: p6,
            rows: 1,
            cols: 1,
        },
        {
            src: p7,
            source: p7,
            rows: 1,
            cols: 1,
        },
        {
            src: p8,
            source: p8,
            rows: 1,
            cols: 1,
        },
        {
            src: p9,
            source: p9,
            rows: 1,
            cols: 1,
        },
        {
            src: p10,
            source: p10,
            rows: 1,
            cols: 1,
        },
        {
            src: p11,
            source: p11,
            rows: 1,
            cols: 1,
        },
        {
            src: p12,
            source: p12,
            rows: 1,
            cols: 1,
        },
        {
            src: p13,
            source: p13,
            rows: 1,
            cols: 1,
        },
    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                SCIENTIFICA
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Date: 01 DECEMBER 2023
                                </Typography>

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                Scientifica 2023 held at National Public School, Yeshwanthpur on Friday, 01 December 2023, witnessed a convergence of diverse departments showcasing innovative projects, experiments and research across multiple disciplines. It embodied the essence of interdisciplinary exploration, showcasing the interconnectedness of scientific knowledge across various subjects. By breaking down barriers between disciplines, this exhibition encouraged a holistic understanding of Science and its applications in fields beyond the traditional scientific realm. 
                                <br></br>
                                The Department of English presented a futuristic twist captivating with interstellar exploration, showcasing the students' creativity and storytelling prowess, a mystique of mythology to the intrigue of history, the laughter of humour, the deduction in detective tales, the rhythm of poetry, the inspiration of a Ted talk, the essence of biography and enigma of science fiction, each enactment unfolds a unique narrative. 
                                <br></br>
                                The Department of Hindi presented a play that showcased the original writing of ‘Andher Nagri Chaupat Raja’ followed by the presentation of the play displaying the modern scenario with the same concept. The play revolved around the moral that the leader has to be wise enough so that he could lead the people on to a wiser path.
                                <br></br>
                                Namma Kannada Department displayed all forms of visual art that was an integral part of folk culture. It reflected the cultural life of a community with a diverse array of folk traditions that the dancers swayed to the rhythm of indigenous melodies, captivating the audience with the grace and authenticity of their performances.
                                <br></br>
                                The Department of Sanskrit brought about the characterisation of the scientific origin of the classical language Sanskrit, to the brief insights of Vedas and Vedangas through panel discussions and the penance of Sage Panini making him a good scholar and a grammarian of Sanskrit that was showcased in the form of a Dance Drama.
                                <br></br>
                                A creative skit on the properties of quadrilaterals was presented by the Department of Mathematics, set in a fictional circle kingdom facing a dilemma. The show witnessed intriguing projects on topics such as Lissajous Figures, 0 – Infinity, Graph Theory, Lithotripsy and more advanced mathematical applications in architecture, high-speed surface vehicle movement, fractals, economics, prediction using graphed factors by the senior students.
                                <br></br>
                                The Department of Physics presented Basic Physics to the Complex Concepts put in the form of models, with a vast variety of topics ranging from the working of sensors, dispensers to practical theoretical concepts of gravitation, charged particles to wormholes digging deep into the intricacies of Science and Modern Technology. Display models of Chandrayaan-3 and Aditya L1 were highlights of their exhibits.
                                <br></br>
                                The enchanting fusion of Science and Creativity could be witnessed at Chemistry Spectacle! They presented 'Art de Titelles' a captivating puppet show, mesmerising stop-motion photography and insightful presentations, 'Musical Chemidy,' a song spoof, 'Flame of Elegance' the fashion walk, where chemistry meets couture and 'The Bonding Chronicles,' a gripping drama that unravelled the mysteries of chemical bonding. “Chemistry in Action” exhibited the wonders of Chemistry.
                                <br></br>
                                The Department of Biology helped everyone to dive into the vast expanse of knowledge with the mind-captivating visuals, experiments, models and a skit to explore the World of Life Sciences. ‘Nature’s Bounty’ was presented with a captivating collection of plant life and deeper understanding of the living world along with ‘walking-talking’ encyclopaedias. They aided to delve into the intricacies of Human Biology with the displays and interactive games. The floating audience witnessed young detectives solving a chilling case with the application of biological forensic techniques. 
                                <br></br>
                                The Department of Social Science exhibited a plethora of thoughtfully curated exhibits like Water Wheel, Rajendra Chola Dynasty, Invention of the Steam Engine, World War 2 and Nuclear Warfare, which delved into historical events, cultural phenomena and contemporary challenges. Hands-on activities and multimedia presentations provided an immersive experience, making complex concepts accessible. A Street Play was enacted on Influence of Social Media and Encouraging Critical Thinking about the World We Live In.
                                <br></br>
                                The Department of Commerce took everyone on a tour around a self-made miniature city that showcased India’s Economic History that educated everyone on the Importance of Commerce in everyday lives. The visitors actively took part in an interactive game to experience the concepts and participated in a quiz to test their current knowledge on Real World Trade and Business.
                                <br></br>
                                The presentation by Computer Science department immersed everyone into a world of innovation with projects like the Library Management System, Future of Emotion Recognition through Facial Expression and Detection with Pixblocks. The hi-tech audience explored the evolution of computers through a captivating story narrated in Scratch and Video Format, witnessed the creation of a game like Roblox, delved into the realm of Mood Detection Models. A tech-filled journey that highlighted how the future unfolded, coupled with a skit that emphasised the importance of Cyber Awareness in this digital age.
                                <br></br>
                                Scientifica isn't confined to a single scientific branch but serves as a bridge that unites multiple subjects through the lens of Science. It provided an immersive and interactive platform where visitors could research into the scientific underpinnings of subjects ranging from languages and social sciences to natural sciences and beyond.
                                <br></br>
                                Visitors left Scientifica 2023 with a newfound appreciation for how scientific principles permeated and enhanced our understanding of subjects beyond the confines of natural sciences, enriching their perspectives and fostering a culture of interdisciplinary collaboration and discovery. Scientifica 2023 showcased the relevance of scientific principles in multiple domains that inspired cross-disciplinary thinking and innovation.
                                </Typography>
                            </Box>
                            <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                               SCIENTIFICA 2023: “Bridging Science across disciplines! / Science is simply the word we use to describe a method of organising the curiosity!”

                                <br />
                            </Typography>

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 300 : 200}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                    <Box marginBottom={4}>
                            <Sidebar2023/>
                        </Box> 

                        {/* <SidebarNewsletter /> */}
                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default Scientifica2023;