import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

// import p1 from 'assests/Photos/SchoolProgram/2024/Nobagday/1.jpg';
// import p2 from 'assests/Photos/SchoolProgram/2024/Nobagday/2.jpg';
// import p3 from 'assests/Photos/SchoolProgram/2024/Nobagday/3.jpg';
// import p4 from 'assests/Photos/SchoolProgram/2024/Nobagday/4.jpg';


import { Typography } from '@mui/material';
import Container from 'components/Container';
import { Sidebar2023 } from 'views/SchoolProgram2023/components';
import Sidebar2024 from '../SchoolProgram2023/components/Sidebar2024';
import Archive2022 from '../SchoolProgram2023/components/Archive2022';


const OnamDay2024 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });


    const [config, setConfig] = useState({ base_image_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
const p1 = `${config.base_image_url}/home/events-activities/school-programs/onam1to5/1.webp`;
const p2 = `${config.base_image_url}/home/events-activities/school-programs/onam1to5/2.webp`;
const p3 = `${config.base_image_url}/home/events-activities/school-programs/onam1to5/3.webp`;
const p4 = `${config.base_image_url}/home/events-activities/school-programs/onam1to5/4.webp`;
const p5 = `${config.base_image_url}/home/events-activities/school-programs/onam1to5/5.webp`;
const p6 = `${config.base_image_url}/home/events-activities/school-programs/onam1to5/6.webp`;
const p7 = `${config.base_image_url}/home/events-activities/school-programs/onam1to5/7.webp`;
const p8 = `${config.base_image_url}/home/events-activities/school-programs/onam1to5/8.webp`;
const p9 = `${config.base_image_url}/home/events-activities/school-programs/onam1to5/9.webp`;
const p10 = `${config.base_image_url}/home/events-activities/school-programs/onam1to5/10.webp`;
const p11 = `${config.base_image_url}/home/events-activities/school-programs/onam1to5/11.webp`;
const p12 = `${config.base_image_url}/home/events-activities/school-programs/onam1to5/12.webp`;

    const photos = [
        {
            src: p1,
            source: p1,
            rows: 1,
            cols: 1,
          },
          {
            src: p2,
            source: p2,
            rows: 1,
            cols: 1,
          },
          {
            src: p3,
            source: p3,
            rows: 1,
            cols: 1,
          },
          {
            src: p4,
            source: p4,
            rows: 1,
            cols: 1,
          },
          {
            src: p5,
            source: p5,
            rows: 1,
            cols: 1,
          },
          {
            src: p6,
            source: p6,
            rows: 1,
            cols: 1,
          },
          {
            src: p7,
            source: p7,
            rows: 1,
            cols: 1,
          },
          {
            src: p8,
            source: p8,
            rows: 1,
            cols: 1,
          },
          {
            src: p9,
            source: p9,
            rows: 1,
            cols: 1,
          },
          {
            src: p10,
            source: p10,
            rows: 1,
            cols: 1,
          },
          {
            src: p11,
            source: p11,
            rows: 1,
            cols: 1,
          },
          {
            src: p12,
            source: p12,
            rows: 1,
            cols: 1,
          }
    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                Onam	
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                 Class: 1-5      Date: : 13 September 2024                                            
                                </Typography>
                                {/* <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                “With pure hearts and beaming smiles, our young learners gathered to celebrate the guiding stars in their lives—their cherished teachers.””

                                <br />
                            </Typography> */}

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                On 13 September 2024, National Public School celebrated Onam with vibrant colors and festive joy, as students, teachers, and staff came together to mark this special harvest festival of Kerala. The day's festivities began with a heartwarming speech on the significance of Onam, delivered by a child dressed as King Mahabali, capturing the essence of the festival and its rich historical roots.
                                    <br></br>
                                    The cultural program opened with a captivating Thiruvathira Kali performance by the younger students, whose graceful movements in traditional attire delighted the audience. This was followed by a melodious group song, setting the tone for the lively Kummatti Kali, a colorful mask dance that brought Kerala’s folklore to life. The program also featured Mohiniyattam, a classical dance known for its grace and elegance, leaving the audience mesmerized.
                                    <br></br>
                                    The celebrations concluded with a thoughtful vote of thanks, marking the end of a day filled with cultural pride and festive cheer. The Onam celebration beautifully showcased Kerala’s rich cultural heritage, with enthusiastic participation from the students. It was a memorable day of learning, joy, and appreciation for traditions that will be cherished by all.    
                                    <br></br>
                                    

                                </Typography>
                            </Box>
                            <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                “Embracing the traditions, spreading the love, and creating unforgettable memories."
                                <br />
                                
                            </Typography>

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 200 : 100}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                    <Box marginBottom={4}>
                            <Sidebar2024/>
                        </Box> 
                        <Box marginBottom={4}>
                            <Archive2022/>
                        </Box>

                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default OnamDay2024;